<template>
  <div class="category-resume">
    <!-- Name -->
    <div class="name">
      <h3>{{ category }}</h3>
      <b-button variant="primary" @click="goToSearch()">{{ $t('general.seeMore') }}</b-button>
    </div>

    <!-- List -->
    <div class="list">
      <vueper-slides
        class="no-shadow slider"
        :bullets="false"
        :touchable="false"
        slide-multiple
        :gap="3"
        :visible-slides="5"
        fixed-height="187px"
      >
        <template #arrow-left>
          <i class="fa fa-chevron-left"></i>
        </template>

        <template #arrow-right>
          <i class="fa fa-chevron-right"></i>
        </template>

        <vueper-slide v-for="(item, i) in list" :key="i">
          <template #content>
            <b-link class="thumbnail-wrapper" @click="openModal(item.id)" @mouseover="setHovered(i, true)" @mouseout="setHovered(i, false)">
              <!-- Image -->
              <slot v-if="item.preview_file">
                <thumbnail-image :image="item.preview_file"></thumbnail-image>
              </slot>
              <!-- Content -->
              <div v-if="hoverStates[i]" class="hover-text">{{ item.name }}</div>
              <slot v-if="item.content">
                <div v-html="item.content"></div>
              </slot>
            </b-link>
          </template>
        </vueper-slide>
        <!--File Group Record -->
        <file-group-record v-if="modal" :fileGroupRecordId="selectedFileGroupRecordId" @closeModal="closeModal"></file-group-record>
      </vueper-slides>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './CategoryResume';
</style>

<script>
import bus from '@/api/bus';
import { VueperSlide, VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  props: {
    category: String,
    category_id: Number,
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedFileGroupRecordId: false,
      modal: false,
      hoverStates: new Array(this.list.length).fill(false),
    };
  },

  components: {
    VueperSlides,
    VueperSlide,
    ThumbnailImage: () => import('@/components/Thumbnails/Image.vue'),
    FileGroupRecord: () => import('@/components/Modals/FileGroupRecord/FileGroupRecord.vue'),
  },
  methods: {
    goToSearch() {
      this.$router.push({ name: 'search', query: { category_id: this.category_id, full: 'yes' } }).catch(() => {});
      bus.$emit('updateFilters');
    },
    openModal(FileGroupRecordId) {
      this.modal = true;
      this.selectedFileGroupRecordId = FileGroupRecordId;
      setTimeout(() => {
        this.$bvModal.show('file-group-record');
      }, 50);
    },
    closeModal() {
      this.modal = false;
      this.$bvModal.hide('file-group-record');
    },
    setHovered(index, value) {
      this.$set(this.hoverStates, index, value);
    },
  },
};
</script>
